import React from "react";
import { roleAccess } from "helpers/RolesPermission";
import { BiPlus, BiPrinter } from "react-icons/bi";
import { PARENT_FORM_CATEGORY_SLUG, USER_ROLE } from "Constants/constant";
import FundingSetup from "pages/SupportCoordination/Funding/FundingSetup/index";
import DirectOpenFirstForm from "components/DynamicForms/DirectOpenFirstForm/DirectOpenFirstForm";
import Appointment from "components/ServiceProvider/Participant/Appointments/index";
import ParticipantImageToolTip from "components/common/Tooltip/ParticipantImageToolTip";
import CustomButton from "components/common/Button/CustomButton";
import CategoryFormsListing from "components/Modals/CategoryFormsListing/CategoryFormsListing";
import NotificationDropDown from "components/common/NotificationDropDown/NotificationDropDown";
import {
  BLK_PORTAL_PREMISSION_KEYS_CHILDREN,
  PERMISSION_KEYS,
  PERMISSION_KEYS_CHILDREN,
} from "store/constants";

const ParticipantProfileHeader = (props) => {
  const { selectedTab, participantInfo, handleAddCaseNote } = props;

  const loadHeaderButton = () => {
    if (roleAccess([USER_ROLE.Sp_Standard_User])) {
      switch (selectedTab) {
        case 1:
          return (
            <CustomButton
              clickHandler={() => {
                window.scrollTo(0, 0);
                window.print();
              }}
              label='Print Support Plan'
              Icon={<BiPrinter />}
            />
          );
        case 9:
          return (
            <CategoryFormsListing
              participantId={participantInfo.id}
                buttonText='Injury management'
              slug={PARENT_FORM_CATEGORY_SLUG.INJURY_MANAGEMENT.SLUG}
              particpantUuid={participantInfo.uuid}
            />
            // <DirectOpenFirstForm
            //   buttonText='Injury management'
            //   participantId={participantInfo.id}
            //   slug={PARENT_FORM_CATEGORY_SLUG.INJURY_MANAGEMENT.SLUG}
            //   particpantUuid={participantInfo.uuid}
            // />
          );
        case 5:
          return (
            <CategoryFormsListing
              participantId={participantInfo.id}
              buttonText='Medication Plan'
              slug={PARENT_FORM_CATEGORY_SLUG.MEDICATION.SLUG}
              particpantUuid={participantInfo.uuid}
            />
          );
        case 8:
          return (
            <CategoryFormsListing
              participantId={participantInfo.id}
              buttonText='High Care'
              slug={PARENT_FORM_CATEGORY_SLUG.HIGH_CARE.SLUG}
              particpantUuid={participantInfo.uuid}
            />
          );
        case 17:
          return roleAccess(
            [],
            PERMISSION_KEYS.PARTICIPANT,
            BLK_PORTAL_PREMISSION_KEYS_CHILDREN.PARTICIPANT.FUNDING.CREATE
          ) ? (
            <FundingSetup type='Header_button' />
          ) : (
            <></>
          );
        default:
          <></>;
      }
    } else {
      switch (selectedTab) {
        case 1:
          return (
            <CustomButton
              clickHandler={() => {
                window.scrollTo(0, 0);
                window.print();
              }}
              label='Print Support Plan'
              Icon={<BiPrinter />}
            />
          );
        case 3:
          return (
            <CategoryFormsListing
              participantId={participantInfo.id}
              buttonText='Support Plan'
              slug={PARENT_FORM_CATEGORY_SLUG.SUPPORT_PLAN.SLUG}
              particpantUuid={participantInfo.uuid}
            />
          );
        case 5:
          return (
            <CategoryFormsListing
              participantId={participantInfo.id}
              buttonText='Medication Plan'
              slug={PARENT_FORM_CATEGORY_SLUG.MEDICATION.SLUG}
              particpantUuid={participantInfo.uuid}
            />
          );
        case 6:
          return (
            <CategoryFormsListing
              participantId={participantInfo.id}
              buttonText='Participant Assessment'
              slug={PARENT_FORM_CATEGORY_SLUG.PARTICIPANT_ASSESSMENT.SLUG}
              particpantUuid={participantInfo.uuid}
            />
          );
        case 7:
          return (
            <CategoryFormsListing
              participantId={participantInfo.id}
              buttonText='Risk Management'
              slug={PARENT_FORM_CATEGORY_SLUG.RISK_MANAGEMENT.SLUG}
              particpantUuid={participantInfo.uuid}
            />
          );
        case 14:
          return (
            <CategoryFormsListing
              participantId={participantInfo.id}
              buttonText='Person Centred Plan'
              slug={PARENT_FORM_CATEGORY_SLUG.PERSON_CENTERED_PLAN.SLUG}
              particpantUuid={participantInfo.uuid}
            />
          );
        case 8:
          return (
            <CategoryFormsListing
              participantId={participantInfo.id}
              buttonText='High Care'
              slug={PARENT_FORM_CATEGORY_SLUG.HIGH_CARE.SLUG}
              particpantUuid={participantInfo.uuid}
            />
          );
        case 9:
          return (
            <CategoryFormsListing
              participantId={participantInfo.id}
              buttonText='Injury management'
              slug={PARENT_FORM_CATEGORY_SLUG.INJURY_MANAGEMENT.SLUG}
              particpantUuid={participantInfo.uuid}
            />
          );
        case 11:
          return (
            <CategoryFormsListing
              participantId={participantInfo.id}
              buttonText='Risk Management'
              slug={PARENT_FORM_CATEGORY_SLUG.RISK_MANAGEMENT.SLUG}
              particpantUuid={participantInfo.uuid}
            />
          );
        case 12:
          return (
            <Appointment
              type='header_button'
              participantInfo={participantInfo}
            />
          );
        case 17:
          return <FundingSetup type='Header_button' />;
        case 18:
          return (
            <CategoryFormsListing
              buttonText='Exit/Transition'
              participantId={participantInfo.id}
              slug={PARENT_FORM_CATEGORY_SLUG.Exit_Transaction.SLUG}
              particpantUuid={participantInfo.uuid}
            />
          );
        default:
          <></>;
      }
    }
  };

  const casePermissionHandle = () => {
    if (
      roleAccess(
        [USER_ROLE.Sp_Standard_User],
        PERMISSION_KEYS.PARTICIPANT,
        BLK_PORTAL_PREMISSION_KEYS_CHILDREN.PARTICIPANT.CASE_NOTES.STORE
      ) &&
      roleAccess(
        [USER_ROLE.Sp_Standard_User],
        PERMISSION_KEYS.PARTICIPANT,
        BLK_PORTAL_PREMISSION_KEYS_CHILDREN.PARTICIPANT.CASE_NOTES.SHOW
      )
    ) {
      return true;
    } else if (roleAccess([USER_ROLE.Sp_manager, USER_ROLE.Sp_Admin])) {
      return true;
    }
    return false;
  };

  return (
    <>
      {participantInfo && (
        <div className='px-6 flex justify-between items-center bg-white h-[80px] border-b border-gray-200'>
          <div className='mt-5 mb-5 flex items-center space-x-4'>
            <div className='h-10 w-10 flex-shrink-0 group relative'>
              <ParticipantImageToolTip
                cssClass={"bg-black py-1 px-1 rounded text-white z-[9]"}
                imgCssClass={`${
                  participantInfo.profile_picture
                    ? "object-cover w-16 h-16 rounded-full lg:w-10 lg:h-10 print:w-10 print:h-10"
                    : "object-cover rounded-full border-4 border-white"
                }`}
                participant={participantInfo}
              />
            </div>
            <div>
              <p className='capitalize text-gray-900 text-lg font-medium'>{`${participantInfo.first_name} ${participantInfo.last_name}`}</p>
              {roleAccess(
                [],
                PERMISSION_KEYS.PARTICIPANT,
                PERMISSION_KEYS_CHILDREN.PARTICIPANT.PRIMARY_EMAIL
              ) && (
                <p className='text-gray-500 break-all'>
                  {participantInfo.email}
                </p>
              )}
            </div>
          </div>
          <div className='flex space-x-4 items-center text-sm print:hidden'>
            <div>{loadHeaderButton()}</div>
            {casePermissionHandle() && (
              <CustomButton
                label='Case Notes'
                clickHandler={handleAddCaseNote}
                Icon={<BiPlus />}
                variant='secondary'
                cssClass='hidden sm:flex'
              />
            )}
            <div className='flex sm:mt-0 sm:ml-4'>
              <NotificationDropDown />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ParticipantProfileHeader;
