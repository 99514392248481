import { roleAccess } from "helpers/RolesPermission";
import { USER_ROLE } from "../../Constants/constant";
import { apiCall } from "./service";
import {
  URL_SP_PARTICIPANTS,
  URL_SP_APPLICANTS,
  URL_SP_EMPLOYEE_DOCUMENTS,
  URL_SP_DASHBOARD,
  URL_SP_FEEDBACK,
  URL_SP_WORKER,
  URL_SP_FORM_CATEGGORES,
  URL_SP_CRM_FORM_TEMPLATES,
  URL_SP_DOCUMENTS,
  URL_HRM_DOCUMENTS,
  URL_SP_RISK_RATING_TAB_LIST,
  URL_SP_CREATE_MANAGER,
  URL_SP_SUBMITTED_FORMS_LIST,
  URL_HRM_SUBMITTED_FORMS_LIST,
  URL_SP_LIST_MANAGER,
  URL_SP_POLICIES_DELETE,
  URL_SP_COMPLAINT,
  URL_HRM_GET_TEAM_MANAGMENT,
  URL_SP_UPDATE_DOCUMENTS,
  URL_SP_DELETE_DOCUMENTS,
  URL_SP_SETTING,
  URL_HRM_WORKER_GENERAL_SETTING,
  URL_HRM_WORKER_PROFILE_DETAIL,
  URL_HRM_WORKER_STATUS_UPDATE,
  URL_SUPER_ADMIN_CRM_FORM_TEMPLATES,
  URL_SUPER_ADMIN_HRM_FORM_TEMPLATES,
  URL_SUPER_ADMIN_CRM_FORM_CATEGORIES,
  URL_SUPER_ADMIN_HRM_FORM_CATEGORIES,
  URL_SP_HRM_SUBMITTED_FORMS_LIST,
  URL_SP_HRM_FORM_TEMPLATES,
  URL_HRM_FORM_CATEGORIES,
  URL_SP_MANAGER_ROLE,
  URL_SP_STRIPE_PRICING_TABLE_LIST,
  URL_SP_STRIPE_ATTACH_PAYMENT_METHOD,
  URL_SP_STRIPE_LIST_ALL_CARD,
  URL_SP_STRIPE_BUY_PLAN,
  URL_SP_STRIPE_GET_DEFAULT_PAYMENT_METHOD,
  URL_SP_STRIPE_CURRENT_SUBSCRIPTION,
  URL_SP_STRIPE_MAKE_CARD_DEFAULT,
  URL_SP_STRIPE_DETTACH_CARD,
  URL_SP_STRIPE_INVOICES,
  URL_PAYROLL_CONNECT,
  URL_PAYROLL_QUICKBOOKS_BUSINESSES,
  URL_SP_STRIPE_RETRY_PAYMENT,
  URL_PAYROLL_MYOB_BUSINESSES,
  URL_PAYROLL_MYOB_EMPLOYEES,
  URL_PAYROLL_SYNC_MYOB_EMPLOYEES,
  URL_PAYROLL_MYOB_CATEGORIES,
  URL_PAYROLL_SYNC_MYOB_CATEGORIES,
  URL_SP_STRIPE_COUPON_INFO,
  URL_SP_STAFF_COMPLIANCE_SETTING,
  URL_SP_STAFF_COMPLIANCE_SETTING_UPDATE,
  URL_SP_DASHBOARD_INPROGRESS_SHIFTS,
  URL_SP_DELETE_CASE_NOTE_ATTACHMENT,
  URL_HRM_DELETE_CASE_NOTE_ATTACHMENT,
} from "./URL";
import { URL_SP_QUESTION } from "./URL";

const token = localStorage.getItem("accessToken");
export const api = {
  participantListRequest(params) {
    return apiCall.post({
      url: URL_SP_PARTICIPANTS,
      isAuthToken: token,
      params: params.filters,
      bodyParams: params.bodyFilter,
    });
  },
  applicantsListRequest() {
    return apiCall.get({
      url: URL_SP_APPLICANTS,
      isAuthToken: token,
      params: {},
    });
  },
  employeeDocumentsRequest() {
    return apiCall.get({
      url: URL_SP_EMPLOYEE_DOCUMENTS,
      isAuthToken: token,
      params: {},
    });
  },
  dashboardRequest() {
    return apiCall.get({
      url: URL_SP_DASHBOARD,
      isAuthToken: token,
      params: {},
    });
  },
  spWorkerRequest() {
    return apiCall.get({ url: URL_SP_WORKER, isAuthToken: token, params: {} });
  },
  feedbackRequest(params) {
    return apiCall.get({
      url: URL_SP_FEEDBACK,
      isAuthToken: token,
      params: params,
    });
  },
  getComplaintsRequests(params) {
    return apiCall.get({
      url: URL_SP_COMPLAINT,
      isAuthToken: token,
      params: params,
    });
  },
  getQuestionsRequests(params) {
    return apiCall.get({
      url: URL_SP_QUESTION,
      isAuthToken: token,
      params: params,
    });
  },

  formCategoriesRequest(payload) {
    let url = null;
    if (roleAccess([USER_ROLE.Super_Admin])) {
      url =
        payload?.role === "hrm"
          ? URL_SUPER_ADMIN_HRM_FORM_CATEGORIES
          : URL_SUPER_ADMIN_CRM_FORM_CATEGORIES;
    } else if (roleAccess([USER_ROLE.Sp_Admin, USER_ROLE.Sp_manager])) {
      url =
        payload?.role === "hrm"
          ? URL_HRM_FORM_CATEGORIES
          : URL_SP_FORM_CATEGGORES;
    }
    if (url === null) return;

    return apiCall.get({
      url,
      isAuthToken: token,
      params: {},
    });
  },

  // spHrmAllFormRequest(params) {
  //   return apiCall.post({
  //     url: roleAccess([USER_ROLE.Sp_Admin])
  //       ? URL_SP_CRM_FORM_TEMPLATES
  //       : params?.hrm
  //       ? URL_SUPER_ADMIN_HRM_FORM_TEMPLATES
  //       : URL_SUPER_ADMIN_FORM_TEMPLATES,
  //     isAuthToken: token,
  //     params: params.filters,
  //     bodyParams: params.bodyFilter,
  //     skipQueryParams: true,
  //   });
  // },

  allFormsRequest(params) {
    const parm = {
      ...params.filters,
    };

    let url = null;
    if (roleAccess([USER_ROLE.Super_Admin])) {
      url =
        params?.role === "hrm"
          ? URL_SUPER_ADMIN_HRM_FORM_TEMPLATES
          : URL_SUPER_ADMIN_CRM_FORM_TEMPLATES;
    } else if (roleAccess([USER_ROLE.Sp_Admin, USER_ROLE.Sp_manager])) {
      url =
        params?.role === "hrm"
          ? URL_SP_HRM_FORM_TEMPLATES
          : URL_SP_CRM_FORM_TEMPLATES;
    }
    if (url === null) return;

    return apiCall.post({
      url: url,
      isAuthToken: token,
      params: parm,
      bodyParams: params.bodyFilter,
      skipQueryParams: true,
    });
  },
  DocumentsRequest(params) {
    return apiCall.post({
      url: URL_SP_DOCUMENTS,
      isAuthToken: token,
      params: params.filters,
      bodyParams: params.bodyFilter,
    });
  },
  documentDeleteRequest(params) {
    return apiCall.get({
      url: `${URL_SP_DELETE_DOCUMENTS}?doc_id=${params.documentId}`,
      isAuthToken: token,
      params: {},
      skipQueryParams: true,
    });
  },
  updateSpDocumentService(params) {
    return apiCall.post({
      url: URL_SP_UPDATE_DOCUMENTS,
      isAuthToken: token,
      params,
    });
  },
  DocumentsHrmRequest(params) {
    return apiCall.post({
      url: URL_HRM_DOCUMENTS,
      isAuthToken: token,
      params: params.filters,
      bodyParams: params.bodyFilter,
    });
  },
  RiskRatingTabListSPRequest(params) {
    return apiCall.get({
      url: URL_SP_RISK_RATING_TAB_LIST,
      isAuthToken: token,
      params: params.filters,
      bodyParams: params.bodyFilter,
    });
  },
  createSPManager(params) {
    return apiCall.post({
      url: URL_SP_CREATE_MANAGER,
      isAuthToken: token,
      params: params,
    });
  },
  updateSpManager(params) {
    return apiCall.post({
      url: URL_SP_MANAGER_ROLE,
      isAuthToken: token,
      params,
    });
  },
  getSPManager() {
    return apiCall.get({
      url: URL_SP_LIST_MANAGER,
      isAuthToken: token,
      params: {},
    });
  },
  getSPSupportPlanSubmittedFormsListRequest(params) {
    if (params.role === "hrm") {
      params = {
        c_id: params.c_id,
        userType: params.userType,
        worker_id: params.pt_id,
        bodyFilter: params.bodyFilter,
        filters: {
          c_id: params.filters.c_id,
          page: params.filters.page,
          per_page: params.filters.per_page,
          worker_id: params.pt_id,
        },
      };
      return apiCall.post({
        url: `${URL_SP_HRM_SUBMITTED_FORMS_LIST}?page=${params.filters.page}&per_page=${params.filters.per_page}&c_id=${params.c_id}&worker_id=${params.worker_id}`,
        skipQueryParams: true,
        isAuthToken: token,
        params,
        bodyParams: params.bodyFilter,
      });
    } else {
      return apiCall.post({
        url:
          params.userType === USER_ROLE.Sp_Standard_User
            ? URL_HRM_SUBMITTED_FORMS_LIST
            : params.role === "hrm"
            ? URL_SP_HRM_SUBMITTED_FORMS_LIST
            : URL_SP_SUBMITTED_FORMS_LIST,
        isAuthToken: token,
        params: params.filters,
        bodyParams: params.bodyFilter,
      });
    }
  },

  deleteSpPolicy(params) {
    return apiCall.delete({
      url: `${URL_SP_POLICIES_DELETE}/${params.uuid}`,
      isAuthToken: token,
      params: {},
    });
  },
  teamManagmentListRequest(params) {
    return apiCall.post({
      url: URL_HRM_GET_TEAM_MANAGMENT,
      isAuthToken: token,
      params: params.filters,
      bodyParams: params.bodyFilter,
    });
  },
  workerProfileSettingRequest(params) {
    return apiCall.get({
      url: `${URL_HRM_WORKER_PROFILE_DETAIL}?profile_id=${params.profile_id}`,
      isAuthToken: token,
      params: {},
    });
  },
  workerHourlyRateSettingUpdateRequest(params) {
    return apiCall.post({
      url: `${URL_HRM_WORKER_GENERAL_SETTING}`,
      isAuthToken: token,
      params,
      skipQueryParams: true,
    });
  },

  workerStatusUpdateRequest(params) {
    return apiCall.post({
      url: `${URL_HRM_WORKER_STATUS_UPDATE}?profile_id=${params.profile_id}`,
      isAuthToken: token,
      params,
      skipQueryParams: true,
    });
  },

  spSettingRequest() {
    return apiCall.get({
      url: URL_SP_SETTING,
      isAuthToken: token,
      params: {},
    });
  },
  spSettingUpdate(params) {
    return apiCall.post({
      url: URL_SP_SETTING,
      isAuthToken: token,
      params,
    });
  },

  //Payroll
  connectPayrollIntegration(params) {
    return apiCall.post({
      url: URL_PAYROLL_CONNECT,
      isAuthToken: token,
      params,
    });
  },
  getQuickBookBusinesses() {
    return apiCall.get({
      url: URL_PAYROLL_QUICKBOOKS_BUSINESSES,
      isAuthToken: token,
      params: {},
    });
  },

  //MYOB
  getMyobBusinesses() {
    return apiCall.get({
      url: URL_PAYROLL_MYOB_BUSINESSES,
      isAuthToken: token,
      params: {},
    });
  },
  getMyobEmployees(params) {
    return apiCall.get({
      url: params.myob_company_id
        ? `${URL_PAYROLL_MYOB_EMPLOYEES}?myob_company_id=${params.myob_company_id}`
        : URL_PAYROLL_MYOB_EMPLOYEES,
      isAuthToken: token,
      params: {},
    });
  },
  syncMyobEmployees(params) {
    return apiCall.get({
      url: params.myob_company_id
        ? `${URL_PAYROLL_SYNC_MYOB_EMPLOYEES}?myob_company_id=${params.myob_company_id}`
        : URL_PAYROLL_SYNC_MYOB_EMPLOYEES,
      isAuthToken: token,
      params: {},
    });
  },
  getMyobCategories(params) {
    return apiCall.get({
      url: params.myob_company_id
        ? `${URL_PAYROLL_MYOB_CATEGORIES}?myob_company_id=${params.myob_company_id}`
        : URL_PAYROLL_MYOB_CATEGORIES,
      isAuthToken: token,
      params: {},
    });
  },
  syncMyobCategories(params) {
    return apiCall.get({
      url: params.myob_company_id
        ? `${URL_PAYROLL_SYNC_MYOB_CATEGORIES}?myob_company_id=${params.myob_company_id}`
        : URL_PAYROLL_SYNC_MYOB_CATEGORIES,
      isAuthToken: token,
      params: {},
    });
  },

  //Stripe
  stirpePricingTableList() {
    return apiCall.get({
      url: URL_SP_STRIPE_PRICING_TABLE_LIST,
      isAuthToken: true,
      params: {},
    });
  },
  stirpeSaveCardDataApi(params) {
    return apiCall.post({
      url: URL_SP_STRIPE_ATTACH_PAYMENT_METHOD,
      isAuthToken: true,
      params,
    });
  },
  stirpeListAllCardApi() {
    return apiCall.get({
      url: URL_SP_STRIPE_LIST_ALL_CARD,
      isAuthToken: true,
      params: {},
    });
  },
  stripeBuyPlanApi(params) {
    return apiCall.post({
      url: URL_SP_STRIPE_BUY_PLAN,
      isAuthToken: true,
      params,
    });
  },
  stirpeGetDefaultPaymentMethod() {
    return apiCall.get({
      url: URL_SP_STRIPE_GET_DEFAULT_PAYMENT_METHOD,
      isAuthToken: true,
      params: {},
    });
  },
  stripeCurrentSubscription() {
    return apiCall.get({
      url: URL_SP_STRIPE_CURRENT_SUBSCRIPTION,
      isAuthToken: true,
      params: {},
    });
  },
  stripeMakeDefaultCard(params) {
    return apiCall.post({
      url: URL_SP_STRIPE_MAKE_CARD_DEFAULT,
      isAuthToken: true,
      params,
    });
  },
  stripeDettachCard(params) {
    return apiCall.post({
      url: URL_SP_STRIPE_DETTACH_CARD,
      isAuthToken: true,
      params,
    });
  },
  stripeInvoices(params) {
    return apiCall.get({
      url: `${URL_SP_STRIPE_INVOICES}?status=${params.status}`,
      isAuthToken: true,
      params: {},
    });
  },
  stripeRetryPayment() {
    return apiCall.post({
      url: `${URL_SP_STRIPE_RETRY_PAYMENT}`,
      isAuthToken: true,
      params: {},
    });
  },
  stripeCouponInfo(params) {
    return apiCall.post({
      url: URL_SP_STRIPE_COUPON_INFO,
      isAuthToken: true,
      params,
    });
  },
  getSpStaffComplianceSettingApi() {
    return apiCall.get({
      url: URL_SP_STAFF_COMPLIANCE_SETTING,
      isAuthToken: true,
      params: {},
    });
  },

  updateSpStaffComplianceSettingApi(params) {
    return apiCall.post({
      url: URL_SP_STAFF_COMPLIANCE_SETTING_UPDATE,
      isAuthToken: true,
      params,
    });
  },
  getSpDashboardInprogressShifts(payload) {
    return apiCall.get({
      url: `${URL_SP_DASHBOARD_INPROGRESS_SHIFTS}?timezone=${payload}`,
      isAuthToken: true,
      params: { page: 1, per_page: 100 },
    });
  },

  deleteCaseNoteAttachment(case_note_uuid, attachment_uuid, roleType) {
    return apiCall.delete({
      url: `${
        roleType === "sp"
          ? URL_SP_DELETE_CASE_NOTE_ATTACHMENT
          : URL_HRM_DELETE_CASE_NOTE_ATTACHMENT
      }/${case_note_uuid}/${attachment_uuid}`,
      isAuthToken: true,
      params: { page: 1, per_page: 100 },
      skipQueryParams: true,
    });
  },
};

export default api;
